define(['app', 'leaflet'], (app, L) => {

  const storeDetailMap = () => {
    const component = {};
    component.app = app;

    const _config = {
      mapError: 'locatorMap_error',
      locatorMapDone: 'locatorMap-done',
      customIcon: {
        beforeSearch: 'locatorMap_customMarker',
        afterSearch: 'locatorMap_customMarker-afterSearch'
      },
      selectors: {
        mapId: 'storelocator',
        locatorMapErrorMessage: '.locatorMap_error_message',
        locatorMapErrorRefresh: '.locatorMap_error_refresh'
      },
      attributes: {
        mapLayer: '[data-js-element=locatorMap]',
        latLong: '[data-latLong]',
      },
      classNames: {
        show: 'show',
      }
    };

    const _init = (element) => {
      let callbackName = component.JSONP(component.initMap);
      component.element = element;
      component.latLong = document.querySelector(component.config.attributes.latLong);
      component.markers = [];
      component.locatorMapErrorMessage = element.querySelector(component.config.selectors.locatorMapErrorMessage);
      component.locatorMapErrorRefresh = element.querySelector(component.config.selectors.locatorMapErrorRefresh);
      component.elements();
      component.loadMapsAPI(`https://maps.googleapis.com/maps/api/js?key=AIzaSyDAe9q-8nn_s6GdxTOsV1mI_JYpQR7wKuw&callback=${callbackName}&libraries=places`);

      return component;
    };

    const _elements = () => {
      component.isMobile = !L.Browser.mobile;
      component.mapTile = document.querySelector(component.config.attributes.mapLayer);
    };

    const _JSONP = (callback) => {
      let timestamp = Date.now();
      let generatedFunctionName = 'jsonp' + Math.round(timestamp + Math.random() * 1000001);

      window[generatedFunctionName] = () => {
        callback();
        window[generatedFunctionName] = undefined;
      };

      setTimeout(() => {
        if (window[generatedFunctionName]) {
          window[generatedFunctionName] = undefined;
          component.errorMessage();
        }
      }, 5000);

      return generatedFunctionName;
    };

    const _errorMessage = () => {
      app.element.addClass(component.config.mapError, component.mapTile);
      component.locatorMapErrorMessage.classList.add(component.config.classNames.show);
      component.locatorMapErrorRefresh.classList.add(component.config.classNames.show);
    };

    const _loadMapsAPI = (url) => {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.setAttribute('async', '');
      script.setAttribute('defer', '');
      script.src = url;
      document.body.appendChild(script);
    };

    const _initMap = () => {
      component.searchResult = false;
      let latlong = component.latLong.getAttribute('data-latlong');
      let centerCoord = latlong.split(',');
      component.storelocator = L.map(component.config.selectors.mapId, {
        dragging: component.isMobile,
        tap: component.isMobile,
        minZoom: 3
      }).setView(centerCoord, 15);

      component.getMapStyle('/mapStyling.locator');

      component.renderMarker();
    };

    const _getMapStyle = (query) => {
      app.ajax.get({
        url: query,
        success: component.onSuccess,
        error: component.onError
      });
    };

    const _onSuccess = (res) => {
      component.jsonObj = JSON.parse(res);
      component.mapStyle = component.jsonObj.mapStyle !== undefined ? component.jsonObj.mapStyle : null;
      app.element.addClass(component.config.locatorMapDone, component.mapTile);
      L.gridLayer.googleMutant({
        type: 'roadmap',
        styles: component.mapStyle
      }).addTo(component.storelocator);
    };

    const _onError = () => {
      console.error('Custom map style not loaded, fallback to default google map');
    };

    const _renderMarker = () => {
      let latlong = component.latLong.getAttribute('data-latlong');
      let markerData = latlong.split(',');

      let icon = L.divIcon({
        className: !component.searchResult ? component.config.customIcon.beforeSearch : component.config.customIcon.afterSearch,
        iconSize: [40, 40],
        bgPos: [0, 0],
        iconAnchor: [20, 40],
        html: `<div class="locatorMap_marker_number"></div>`
      });

      let marker = L.marker(markerData, {
        icon: icon
      });

      component.markers.push(marker);
      marker.addTo(component.storelocator);
    };

    component.config = _config;
    component.init = _init;
    component.elements = _elements;
    component.errorMessage = _errorMessage;
    component.JSONP = _JSONP;
    component.loadMapsAPI = _loadMapsAPI;
    component.getMapStyle = _getMapStyle;
    component.onSuccess = _onSuccess;
    component.onError = _onError;
    component.initMap = _initMap;
    component.renderMarker = _renderMarker;
    return component;
  };

  return storeDetailMap;
});
